import React, { Component } from "react";
//import { IntlProvider, addLocaleData } from "react-intl";
// import locale_en from "react-intl/locale-data/en";
// import locale_fr from "react-intl/locale-data/fr";
// import locale_zh from "react-intl/locale-data/zh";
import { IntlProvider } from "react-intl";
import en_messages from "./locales/en-US";
import fr_messages from "./locales/fr-FR";
import zh_messages from "./locales/zh";
import zh_MO_messages from "./locales/zh-MO";

//addLocaleData([...locale_en, ...locale_fr, ...locale_zh]);

export const messages = {
  "fr-FR": fr_messages,
  "en-US": en_messages,
  zh: zh_messages,
  "zh-MO": zh_MO_messages,

};
const lang = navigator.language || navigator.userLanguage;
export let defualtLocale =
  localStorage.getItem("locale") || lang.substring(0, lang.indexOf("-"));
defualtLocale = messages[defualtLocale] ? defualtLocale : "en-US";

class IntlProviderWrapper extends Component {
  state = { locale: defualtLocale };

  changeLocale = (locale) => {
    localStorage.setItem("locale", locale);
    this.setState({ locale });
  };

  render() {
    const { locale } = this.state;
    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        {this.props.children({ setLocale: this.changeLocale, locale })}
      </IntlProvider>
    );
  }
}

export default IntlProviderWrapper;
